<script lang="ts">import { faCircleInfo, faRightFromBracket, faUser } from "@fortawesome/free-solid-svg-icons";
import { getSessionContext } from "../context";
import FaIcon from "../fa-icon.svelte";
import Popper from "../popper.svelte";
const { session } = getSessionContext();
let target;
let show = false;
$: menu = [
    [
        ($session.state === "success" && $session.data.urls?.logout) || "/logout",
        "Logout",
        faRightFromBracket,
    ],
    ["/account", "Info", faCircleInfo],
];
</script>

<!-- svelte-ignore a11y-mouse-events-have-key-events -->
<li
  class="whitespace-nowrap"
  bind:this={target}
  on:mouseover={() => (show = true)}
  on:mouseleave={() => (show = false)}
  data-sveltekit-reload
>
  {#if $session.authed}
    <span class={$$props.class}>
      <FaIcon icon={faUser} />
      Account
    </span>
    <Popper {target} {show}>
      <ul class="m-4 px-4 py-2 bg-white rounded drop-shadow">
        {#each menu as [href, name, icon]}
          <li>
            <a class="block py-2 hover:text-primary" {href}>
              <FaIcon {icon} fw />
              {name}
            </a>
          </li>
        {/each}
      </ul>
    </Popper>
  {:else}
    <a class={$$props.class} href="/wp-login.php">
      <FaIcon icon={faUser} />
      Login
    </a>
  {/if}
</li>
