<script lang="ts">import FaIcon from "../fa-icon.svelte";
export let entry;
</script>

<li>
  <a
    class="flex items-center gap-2 py-4 hover:text-primary"
    href={entry.href}
    rel={entry.nofollow ? "nofollow" : undefined}
    data-sveltekit-reload={entry.reload ?? true}
    on:click
  >
    {#if typeof entry.icon === "string"}
      <i class={entry.icon} />
    {:else if typeof entry.icon === "object"}
      <FaIcon icon={entry.icon} fw />
    {/if}
    <span>{entry.name}</span>
  </a>
</li>
