<script lang="ts">
  import type { HeadlessSession } from "@fxwrapr/schemas";
  import { Header } from "@fxwrapr/web-common";
  import {
    type CartOptimisticData,
    type EdgeClientConfig,
    initEdgeConfigContext,
    setSessionContext,
  } from "@fxwrapr/web-common/context";
  import { onMount } from "svelte";
  import { cart, session } from "$lib/stores";

  export let optimistic: { auth: boolean; cart: CartOptimisticData };
  export let edge: EdgeClientConfig;
  export let initializer: HeadlessSession | undefined = undefined;

  setSessionContext({ session, cart });
  initEdgeConfigContext(edge);

  onMount(() => {
    session.init(optimistic.auth, initializer);
    cart.init(optimistic.cart);
  });
</script>

<Header
  admin={$session.state === "success" && !!$session.data.capabilities?.admin}
  navigation={edge.nav?.header ?? []}
  imageUrlBase={import.meta.env.PUBLIC_IMAGE_URL}
  tenantId={import.meta.env.PUBLIC_WEB_TENANT}
/>
