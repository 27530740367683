<script lang="ts">import TopNavLink from "./top-nav-link.svelte";
import TopNavMenu from "./top-nav-menu.svelte";
export let navigation;
</script>

<nav class="lt-lg:hidden grow" aria-label="Main">
  <ul class="flex-center divide-x-(1 neutral-4)">
    {#each navigation as entry, i}
      {#if "menu" in entry}
        <TopNavMenu {entry} {i} />
      {:else}
        <TopNavLink {entry} {i} />
      {/if}
    {/each}
  </ul>
</nav>
