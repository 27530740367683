<script lang="ts" context="module">import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { OverlayScrollbarsComponent } from "overlayscrollbars-svelte";
import { fade } from "svelte/transition";
import IconCartItem from "./icon-cart-item.svelte";
import { getSessionContext } from "../context";
import FaIcon from "../fa-icon.svelte";
import { breakpoint } from "../media";
import Popper from "../popper.svelte";
import { parsePrice } from "../woocommerce";
</script>

<script lang="ts">export let imageUrlBase;
export let target;
export let show = false;
const { cart } = getSessionContext();
let popper;
$: $cart, popper?.update();
</script>

{#if $breakpoint.xs && ($cart.state === "success" || $cart.state === "loading") && show}
  <div transition:fade={{ duration: 75 }}>
    <Popper bind:this={popper} {target} show>
      <div class="relative mt-8 m-4 max-w-96">
        {#if $cart.state === "loading"}
          <div class="absolute flex-center inset-0 z-10 rounded bg-(black opacity-50)">
            <FaIcon class="text-(white [5em])" icon={faSpinner} pulse />
          </div>
        {/if}
        <div
          class="flex-center p-4 bg-white rounded drop-shadow font-normal box-content"
          class:min-h-[5em]={$cart.state === "loading"}
        >
          {#if $cart.data?.items_count}
            <div>
              <OverlayScrollbarsComponent class="max-h-96 overflow-y-auto" defer>
                <div class="space-y-4">
                  {#if $cart.data.errors?.length}
                    <div class="bg-primary text-white rounded p-2">
                      <div>There are errors in your cart!</div>
                      {#each $cart.data.errors as error}
                        <div>{error.message}</div>
                      {/each}
                    </div>
                  {/if}
                  {#each $cart.data.items as item (item.key)}
                    <IconCartItem {item} {imageUrlBase} />
                  {/each}
                </div>
              </OverlayScrollbarsComponent>
              <div class="space-y-2 pt-2">
                <p>Subtotal: {parsePrice($cart.data.totals, "total_items")}</p>
                <div class="flex gap-2">
                  <a
                    class="block px-2 py-1 bg-primary text-(xs white) font-medium"
                    href="/cart/"
                    data-sveltekit-reload
                  >
                    View Cart
                  </a>
                  <a
                    class="block px-2 py-1 bg-primary text-(xs white) font-medium"
                    href="/checkout/"
                    data-sveltekit-reload
                  >
                    Checkout
                  </a>
                </div>
              </div>
            </div>
          {:else}
            <p>No items in cart.</p>
          {/if}
        </div>
      </div>
    </Popper>
  </div>
{/if}
