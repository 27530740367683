<script lang="ts">import { createPopper } from "@popperjs/core";
import { onMount } from "svelte";
export let show = false;
export let target;
export let options = {
    strategy: "absolute",
    placement: "bottom",
    modifiers: [],
};
export async function update() {
    await popper?.update();
}
let container;
let popper;
onMount(() => {
    popper = createPopper(target, container, options);
});
async function reveal() {
    await Promise.all([
        popper?.setOptions((options) => ({
            ...options,
            modifiers: [...(options.modifiers ?? []), { name: "eventListeners", enabled: true }],
        })),
        popper?.update(),
    ]);
}
async function hide() {
    await popper?.setOptions((options) => ({
        ...options,
        modifiers: [...(options.modifiers ?? []), { name: "eventListeners", enabled: false }],
    }));
}
$: if (popper) {
    if (show)
        void reveal();
    else
        void hide();
}
</script>

<div class="data-[hide]:invisible" bind:this={container} data-hide={show ? undefined : true}>
  <slot />
</div>
